/* eslint-disable no-restricted-globals */
import { IconEdit, IconHeart } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { useGetIsLogged } from "../../hooks/smallHooks";
import { setOpenLogin } from "../../redux/general/action";
import LikeAssistants from "../Add-ons/Likes";

const CardAssistantsNewLayout = ({
  image,
  title,
  description,
  direction = "vertical",
  icon,
  likes,
  name,
  myAssistant,
  edit,
  assistant,
  setOpenModalInfo,
}) => {
  const dispatch = useDispatch();

  const logged = useGetIsLogged();

  return (
    <>
      <div
        className={`card-assistants-new-layout cursor-pointer ${direction}`}
        onClick={() => {
          logged ? setOpenModalInfo() : dispatch(setOpenLogin(true));
        }}
      >
        <img src={image} alt={title} />
        <div className="details">
          <h2 className="m-0">{title}</h2>
          {direction === "horizontal" && (
            <div className="d-flex flex-column gap-2">
              <p className="m-0">{description}</p>
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center gap-2">
                  <img src={icon} alt="" width={16} height={16} />
                  <span
                    className="one-line"
                    style={{ maxWidth: myAssistant ? 100 : "auto" }}
                  >
                    {name}
                  </span>
                </div>
                <LikeAssistants assistant={assistant} likes={likes}/>
                {myAssistant && (
                  <IconEdit
                    style={{ cursor: "pointer" }}
                    width={16}
                    height={16}
                    onClick={e => {
                      e.stopPropagation();
                      if (edit && typeof edit === "function") {
                        edit();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {direction === "vertical" && (
            <>
              <p className="m-0">{description}</p>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div className="d-flex align-items-center gap-2">
                  <img src={icon} alt="" width={16} height={16} />
                  <span
                    className="one-line"
                    style={{ maxWidth: myAssistant ? 100 : "auto" }}
                  >
                    {name}
                  </span>
                </div>
                <LikeAssistants assistant={assistant} likes={likes} />
                {myAssistant && (
                  <IconEdit
                    style={{ cursor: "pointer" }}
                    width={16}
                    height={16}
                    onClick={e => {
                      e.stopPropagation();
                      if (edit && typeof edit === "function") {
                        edit();
                      }
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CardAssistantsNewLayout;
