import { useRedux } from "../../hooks";
import { ButtonDeleteAllChatsI18n } from "./ButtonDeleteAllChats.i18n";
import useTranslation from "../../hooks/useTranslation";
import ButtonDefaultAccount from "./ButtonDefaultAccount";
import { IconLoader2 } from "@tabler/icons-react";
import useDeleteAllChats from "../../utils/deleteAllChats";
import { useChatStore } from "../../state/chat";

interface ButtonDeleteAllChatsProps {
  collapsed?: boolean;
  disabled?: boolean;
}

export function ButtonDeleteAllChats({
  collapsed = false,
  disabled = false,
}: ButtonDeleteAllChatsProps) {
  const { reset } = useChatStore();

  const { handleDeleteAllChats, isDeleting } = useDeleteAllChats();

  const { t } = useTranslation(ButtonDeleteAllChatsI18n);

  const isMobile = window.matchMedia("(max-width: 991.92px)").matches;

  const deleteChats = async () => {
    await handleDeleteAllChats();
    reset();
  };

  return (
    <>
      {collapsed ? (
        <button
          className="btn fs-4 m-0 p-0 border-0"
          onClick={deleteChats}
          disabled={isDeleting || disabled}
        >
          {isDeleting ? (
            <i className="bx bx-loader-circle bx-spin" />
          ) : (
            <i className="bx bxs-message-square-x" />
          )}
          <p className="font-size-10">{t("button.delete-all-chats")}</p>
        </button>
      ) : (
        <ButtonDefaultAccount
          loading={isDeleting}
          loadingIcon={<IconLoader2 className="spin" />}
          border={true}
          width={!isMobile ? "220px" : "180px"}
          textButton={t("button.delete-all-chats")}
          onClick={deleteChats}
          children={undefined}
          disabled={disabled || isDeleting}
          icon={undefined}
          iconType={undefined}
          textAlign={undefined}
        ></ButtonDefaultAccount>
      )}
    </>
  );
}
