/* eslint-disable react-hooks/exhaustive-deps */
// ChatWebSocketContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { ChannelDTO } from "../@DTO/channel";
import { CreditDTO } from "../@DTO/credit";
import { renameChannel } from "../api";
import { useGetConfigsMainChat } from "../hooks/smallHooks";
import { queryClient, QUERY_KEYS } from "../libs/react-query";
import {
  ChatRepository,
  makeChatId,
} from "../services/indexedDB/chat-repository";
import { getBearerToken } from "../services/localStorageHelpers";
import { showErrorNotification } from "../services/notifications";
import { getUser } from "../services/userHelper";
import { useChatStore } from "../state/chat";

export interface MessageEndData {
  event: string;
  data: {
    user: string;
    date: string;
    datetime: string;
    chat_request: string;
    chat_response: {
      role: string;
      content: string;
    };
    parentMongoId: any;
    assistantId: string;
    channelId: string;
    flagImage: boolean;
    userEmail: string;
    _id: string;
  };
  messageId: string;
}

interface ChatWebSocketContextType {
  sendWebsocketMessage: (message: string) => void;
  cancelMessage: () => void;
}

const ChatWebSocketContext = createContext<
  ChatWebSocketContextType | undefined
>(undefined);

export const useChatWebSocketContext = () => {
  const context = useContext(ChatWebSocketContext);
  if (!context) {
    throw new Error(
      "useChatWebSocketContext deve ser usado dentro de um ChatWebSocketProvider"
    );
  }
  return context;
};

export const ChatWebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const configsMainChat = useGetConfigsMainChat();
  const selectedChat = configsMainChat?.chatData;
  const selectedChatId = selectedChat?._id;

  const [retryCount, setRetryCount] = useState(3);

  const {
    setStatus,
    setImageMessage,
    setEndMessage,
    setBroadcastMessage,
    updateMessage,
    setDisableCancelMessage,
    reset,
  } = useChatStore();

  const chatRepository = new ChatRepository();
  const user = getUser();
  const token = getBearerToken();

  const createWebSocketConnection = useCallback(() => {
    const socketDomain = `${process.env.REACT_APP_WEBSOCKET_CHAT_API_URL}/chat`;

    const ws = new WebSocket(
      `${socketDomain}?channelId=${selectedChatId}&authorization=${token}`
    );

    return ws;
  }, [selectedChatId, token]);

  const handleSendError = (message: string) => {
    if (retryCount > 0) {
      setRetryCount(retryCount - 1);
      setTimeout(() => {
        sendWebsocketMessage(message);
      }, 1000);
    } else {
      console.error("Falha ao enviar mensagem após 3 tentativas.");
      setRetryCount(3);
    }
  };

  const connectWebSocket = useCallback(
    (messageToSend?: string) => {
      const ws = createWebSocketConnection();

      ws.onopen = () => {
        setWebSocket(ws);
        setIsConnected(true);
        setRetryCount(3);
        if (messageToSend) {
          try {
            ws.send(messageToSend);
            setRetryCount(3);
          } catch (error) {
            handleSendError(messageToSend);
          }
        }
      };

      ws.onmessage = ({ data }) => {
        if (data === "ping") {
          ws.send("pong");
          return;
        }

        if (data === "pong") {
          return;
        }

        const message = JSON.parse(data);

        if (message?.content?.flagImage === true) {
          return setImageMessage(message?.content);
        }

        receiveSocketMessage(message);
      };

      ws.onerror = error => {
        console.error(`WebSocket error: ${error}`);
        if (retryCount > 0) {
          setRetryCount(retryCount - 1);
          setTimeout(() => {
            connectWebSocket(messageToSend);
          }, 1000);
        } else {
          reset();
          console.error("Erro na conexão WebSocket após 3 tentativas.");
          setRetryCount(3);
        }
      };

      ws.onclose = () => {
        setWebSocket(null);
        setIsConnected(false);
        setRetryCount(prev => prev + 1);
      };

      setWebSocket(ws);
    },
    [
      createWebSocketConnection,
      setWebSocket,
      setIsConnected,
      reset,
      handleSendError,
    ]
  );

  const receiveSocketMessage = async (message: any) => {
    const hasResponse = message?.data?.chat_response?.content !== "";

    switch (message.event) {
      case "status":
        setStatus(message?.content);
        break;

      case "end":
        setEndMessage(message);

        await chatRepository.updateChatWithMessage(
          makeChatId(message.data.channelId, user?.id!),
          message?.data
        );

        if (!hasResponse) {
          showErrorNotification(
            "Erro ao receber mensagem, por favor, tente novamente."
          );
        }

        setDisableCancelMessage(false);
        setStatus("");
        reset();
        break;

      case "saving-chat":
        setDisableCancelMessage(true);
        break;

      case "stopped":
        break;

      case "credits-updated":
        const newCredits = message.content;

        const cachedCredits = queryClient.getQueryData<CreditDTO>(
          QUERY_KEYS.USER_CREDITS
        );
        queryClient.setQueryData(QUERY_KEYS.USER_CREDITS, {
          ...cachedCredits,
          amount: newCredits,
        });
        break;

      case "no-credits":
        showErrorNotification(message?.content);
        reset();
        break;

      case "suggestion-channel-name":
        const suggestionChannelName = message?.content;

        if (suggestionChannelName) {
          queryClient.setQueryData<ChannelDTO[]>(
            QUERY_KEYS.CHANNELS,
            cachedChannels => {
              const newChannels = cachedChannels?.map(channel => {
                if (channel._id === selectedChatId) {
                  return { ...channel, channelName: suggestionChannelName };
                }
                return channel;
              });

              return newChannels || [];
            }
          );

          const params = {
            channelId: selectedChatId,
            channelName: suggestionChannelName,
          };
          renameChannel(params);
        }

        break;

      case "broadcast":
        if (hasResponse) {
          setBroadcastMessage(message);
        }

        break;

      case "error":
        reset();
        break;

      case "error-openrouter":
        showErrorNotification(message?.content);
        reset();
        break;

      default:
        if (message.error) {
          reset();
          return showErrorNotification(message.error);
        }

        if (message?.content) {
          updateMessage(message);
        }
    }
  };

  const sendWebsocketMessage = (message: string) => {
    if (!token) return;

    if (webSocket && isConnected) {
      try {
        webSocket.send(message);
      } catch (error) {
        handleSendError(message);
      }
    } else {
      connectWebSocket(message);
    }
  };

  const cancelMessage = () => {
    if (webSocket && isConnected) {
      setDisableCancelMessage(true);
      webSocket.send(JSON.stringify({ event: "stop-message" }));
    }
  };

  useEffect(() => {
    if (!selectedChatId || !token) return;

    // Fechar a conexão WebSocket existente ao mudar de canal
    if (webSocket) {
      webSocket.close();
    }

    connectWebSocket();

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [selectedChatId]);

  useEffect(() => {
    let localInterval: any;

    function sendPing() {
      if (webSocket && webSocket.readyState === WebSocket.OPEN) {
        webSocket.send("ping");
      } else {
        setWebSocket(null);
      }
    }

    if (webSocket) {
      localInterval = setInterval(sendPing, 20000);
    }

    return () => {
      if (localInterval) {
        clearInterval(localInterval);
      }
    };
  }, [webSocket]);

  return (
    <ChatWebSocketContext.Provider
      value={{ sendWebsocketMessage, cancelMessage }}
    >
      {children}
    </ChatWebSocketContext.Provider>
  );
};

export default ChatWebSocketContext;
