import {
  IconCircleX,
  IconEdit,
  IconFileDownload,
  IconTrash,
} from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { channelExport, deleteChannel, clearChannel } from "../../../../../api";
import {
  showWarningNotification,
  showErrorNotification,
} from "../../../../../services/notifications";
import { useRedux } from "../../../../../hooks";
import { ModalChannelRename } from "../../../../Modals/ModalChannelRename";
import useTranslation from "../../../../../hooks/useTranslation";
import { ChatMenuPopUpI18n } from "../ChatMenuPopUp.i18n";
import {
  ChatRepository,
  makeChatId,
} from "../../../../../services/indexedDB/chat-repository";
import { getCurrentUserLanguage } from "../../../../../services/userHelper";
import { useChatStore } from "../../../../../state/chat";
import { useDeleteChatData } from "../../../../../hooks/smallHooks";
import { setConfigsMainChat } from "../../../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../../../services/chat/defaultConfigsMainChat";

const DropdownTools = ({ channel, setLoading }) => {
  const channelId = channel?.chatData?._id;
  const userId = channel?.chatData?.userId;
  const userLanguage = getCurrentUserLanguage();
  const { setMessages, reset } = useChatStore();
  const [loadingState, setIsLoading] = useState({
    createChannel: false,
    clearChannel: false,
  });
  const [showModalRenameChannel, setShowModalRenameChannel] = useState(false);
  const { t } = useTranslation(ChatMenuPopUpI18n);
  const chatRepository = new ChatRepository();
  const { dispatch } = useRedux();

  const deleteChatData = useDeleteChatData(channelId);

  useEffect(() => {}, [showModalRenameChannel]);

  async function handleChannelClear() {
    if (loadingState.createChannel) return;

    try {
      setIsLoading({ clearChannel: true, createChannel: false });

      const clearChatConfirmText = {
        en: "Would you like to clear the messages from the current chat?",
        pt: "Deseja limpar as mensagens do chat atual?",
        es: "¿Desea borrar los mensajes del chat actual?",
        fil: "Gusto mo bang burahin ang mga mensahe mula sa kasalukuyang chat?",
      };

      const confirmed = window.confirm(clearChatConfirmText[userLanguage]);
      if (confirmed) {
        const hasIndexedDBChat = await chatRepository.getMessagesByChatId(
          makeChatId(channelId, userId)
        );

        if (!hasIndexedDBChat) {
          await clearChannel(channelId);
        }

        await chatRepository.clearChatById(makeChatId(channelId, userId));

        setMessages([]);
      }
    } catch (error) {
      showErrorNotification(error?.message || "Error clearing chat");
      console.error(error);
    } finally {
      setIsLoading({ clearChannel: false, createChannel: false });
    }
  }

  async function handleChannelDelete() {
    setLoading(true);
    const confirmed = window.confirm(t("notifications.delete.confirm"));
    if (confirmed) {
      dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.chatData, null));

      await deleteChannel(channelId);

      await deleteChatData();

      reset();
      setLoading(false);
    }
  }

  async function handleChannelExport() {
    showWarningNotification(t("notifications.export"));

    const messages = await chatRepository.getMessagesByChatId(
      makeChatId(channelId, userId)
    );

    await channelExport({ channelId, messages: messages || [] });
  }

  function onChannelRename() {
    setShowModalRenameChannel(!showModalRenameChannel);
  }

  return (
    <>
      <ul>
        <li onClick={handleChannelExport}>
          <IconFileDownload size={16} className="export-as-pdf" />
          {t("menu.export")}
        </li>
        <li onClick={onChannelRename}>
          <IconEdit size={16} className="rename-conversation" />
          {t("menu.rename")}
        </li>
        <li onClick={handleChannelClear}>
          <IconCircleX size={16} className="clear-conversation" />
          {t("menu.clear")}
        </li>
        <li onClick={handleChannelDelete}>
          <IconTrash size={16} className="delete-conversation" />
          {t("menu.delete")}
        </li>
      </ul>
      <ModalChannelRename
        channel={channel}
        isOpen={showModalRenameChannel}
        onClose={onChannelRename}
      />
    </>
  );
};

export default DropdownTools;
