import { useState, useEffect } from "react";
import { useUser } from "@supabase/auth-helpers-react";
import { showErrorNotification } from "../../services/notifications";
import {
  likeCommunityAssistant,
  unlikeCommunityAssistant,
} from "../../api/assistants";
import { IconHeart, IconHeartFilled } from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";

const LikeAssistants = ({ assistant, likes, sizeQuantityLike }) => {
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isUserLiked, setIsUserLiked] = useState(false);
  const [adjustedLikes, setAdjustedLikes] = useState(likes);
  const user = useUser();

  const localStorageKey = `assistant_like_status`;
  const expirationTime = 90 * 60 * 1000;

  const objectLike = {
    user_id: user?.id,
    flag_like: true,
    flag_dislike: false,
    id_assistant: assistant?._id || assistant?.objectID,
    timestamp: Date.now(),
  };

  const saveToLocalStorage = data => {
    let savedData = JSON.parse(localStorage.getItem(localStorageKey)) || [];

    const existingAssistantIndex = savedData.findIndex(
      item => item.id_assistant === data.id_assistant
    );

    if (existingAssistantIndex !== -1) {
      savedData[existingAssistantIndex] = {
        ...savedData[existingAssistantIndex],
        flag_like: data.flag_like,
        flag_dislike: data.flag_dislike,
        timestamp: Date.now(),
      };
    } else {
      savedData.push(data);
    }

    localStorage.setItem(localStorageKey, JSON.stringify(savedData));
  };

  const getFromLocalStorage = () => {
    const savedData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    return (
      savedData.find(
        item => item.id_assistant === (assistant?._id || assistant?.objectID)
      ) || null
    );
  };

  const clearExpiredLocalStorage = () => {
    const savedData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const currentTime = Date.now();

    const updatedData = savedData.filter(item => {
      const timeDifference = currentTime - item.timestamp;
      return timeDifference < expirationTime;
    });

    localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
  };

  const handleLikeContact = async () => {
    if (isLikeLoading) return;
    try {
      setIsLikeLoading(true);
      setIsUserLiked(true);
      saveToLocalStorage(objectLike);
      setAdjustedLikes(adjustedLikes + 1);
      await likeCommunityAssistant(assistant?._id || assistant?.objectID);
    } catch (error) {
      showErrorNotification("error");
    } finally {
      setIsLikeLoading(false);
    }
  };

  const handleUnlikeContact = async () => {
    if (isLikeLoading) return;
    try {
      setIsLikeLoading(true);
      setIsUserLiked(false);
      saveToLocalStorage({
        ...objectLike,
        flag_like: false,
        flag_dislike: true,
      });
      setAdjustedLikes(adjustedLikes - 1);
      await unlikeCommunityAssistant(assistant?._id || assistant?.objectID);
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsLikeLoading(false);
    }
  };

  useEffect(() => {
    const savedStatus = getFromLocalStorage();
    if (savedStatus) {
      setIsUserLiked(savedStatus.flag_like);
      if (savedStatus.flag_like) {
        setAdjustedLikes(likes + 1);
      } else if (savedStatus.flag_dislike) {
        setAdjustedLikes(likes - 1);
      }
    } else {
      setIsUserLiked(
        assistant?.likedByMe || assistant?.likedby?.includes(user?.id)
      );
      setAdjustedLikes(likes);
    }

    clearExpiredLocalStorage();

    const interval = setInterval(clearExpiredLocalStorage, expirationTime);

    return () => clearInterval(interval);
  }, [assistant?.likedByMe, assistant?.likedby, user?.id, likes]);

  return (
    <div className="d-flex align-items-center gap-1">
      {isUserLiked ? (
        <button
          onClick={e => {
            e.stopPropagation();
            handleUnlikeContact();
          }}
          className="btn heartBeat m-0 p-0 d-flex align-items-center justify-content-center"
        >
          <IconHeartFilled stroke={1.5} color="red" className="sizeIconLike" />
        </button>
      ) : (
        <button
          onClick={e => {
            e.stopPropagation();
            handleLikeContact();
          }}
          className="btn btn-sm m-0 p-0 d-flex align-items-center justify-content-center"
        >
          <IconHeart stroke={1.5} className="sizeIconLike" />
        </button>
      )}
      <span style={{ fontSize: sizeQuantityLike, color: COLORS.gray900 }}>{adjustedLikes}</span>
    </div>
  );
};

export default LikeAssistants;
